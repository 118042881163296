import React, { useEffect, useRef, useState } from "react";

const CompanyDropdownFilter: React.FC<{
  label: string;
  options: string[];
  selectedValue: string;
  onSelect: (value: string) => void;
}> = ({ label, options, selectedValue, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        className="w-fit pl-5 pr-10 py-2 text-sm text-left text-blue-900 bg-white border border-gray-300 rounded-full shadow-sm hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        type="button"
        onClick={toggleDropdown}
      >
        {selectedValue || label}
        <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">
          ▼
        </span>
      </button>
      {isOpen && (
        <ul className="absolute z-10 mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto">
          {options.map((option, index) => (
            <li
              key={index}
              className="px-4 py-2 text-xs cursor-pointer hover:bg-gray-100 text-GTI-BLUE-default"
              onClick={() => {
                onSelect(option);
                setIsOpen(false);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CompanyDropdownFilter;
