import React from "react";

import "./style.css";
import { Link } from "react-router-dom";

interface CompanyCardInterface {
  logo: string;
  title: string;
  description: string;
  productId: string;
}

const CompanyCard = (companyCardInterface: CompanyCardInterface) => {
  const { logo, title, description, productId } = companyCardInterface;

  return (
    <div className="relative max-w-sm mx-auto bg-white shadow-2xl rounded-2xl p-6">
      <div className="text-center mb-4">
        <img src={logo} alt={title} className="mx-auto h-12" />
      </div>
      <h2 className="text-lg font-semibold text-blue-900 text-center mb-2">
        {title}
      </h2>
      <p className="text-gray-600 text-sm text-center mb-6 description">
        {description.split(" ").length > 30
          ? description.split(" ").slice(0, 30).join(" ") + "..."
          : description}
      </p>
      {productId && (
        <div className="text-center">
          <Link
            to={`/product/${productId}`}
            className="bg-blue-900 text-white px-6 py-2 rounded-md shadow-md hover:bg-blue-700 transition absolute -bottom-4 left-1/2 transform -translate-x-1/2"
          >
            View Details
          </Link>
        </div>
      )}
    </div>
  );
};

export default CompanyCard;
