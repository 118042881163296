export enum PROFILE_TYPES {
  DISPLAYER = "DISPLAYER",
  SCOUTER = "SCOUTER",
  GENERAL_SUBSCRIBER = "GENERAL_SUBSCRIBER",
}

export enum SIGNUP_PROFILE_TYPES {
  DISPLAYER = "DISPLAYER",
  SCOUTER = "SCOUTER",
  GENERAL_SUBSCRIBER = "GENERAL_SUBSCRIBER",
}

export enum VerificationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export enum ConnectionResponse {
  CONNECTED = 'Connected',
  ACCEPT = 'Accept',
  CONNECTION_REQUESTED = 'Connection Requested',
  CONNECT = 'Connect',
}