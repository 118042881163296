import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import companiesListRedirect from "../../assests/cohort/company_list_redirect.svg";
import { updates } from "../../shared/cohort/updates";
import { COHORT_COMPANIES } from "../constants";
import gtiLogo from "../../assests/images/gti_logo.svg";
import "./style.css";

const CohortDetails = () => {
  const title = "Dignified Jobs Accelerator Circular Innovation Cohort 2025",
    description =
      "The waste management sector is expected to be a $35.87 billion opportunity by 2028. However, in the current scenario, increasing levels of mismanaged waste and lack of adequate resources, scalable infrastructure and regulations, and relevant or innovative solutions to manage waste is an increasing threat to the waste crisis. Businesses in waste & circularity have the potential to reimagine the sector through innovation and one that is based on social inclusion.";

  return (
    <div className="flex flex-col gap-10 mx-auto p-4 mt-10">
      <Helmet>
        <title>{title}</title>
        <meta name="description" key="description" content={description} />
        <meta name="title" key="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={companiesListRedirect ? companiesListRedirect : gtiLogo}
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/dignified-jobs-accelerator-circular-innovation-cohort-2025`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={companiesListRedirect ? companiesListRedirect : gtiLogo}
        />
        <meta name="twitter:card" content={title} />
      </Helmet>
      <h1 className="text-GTI-BLUE-default text-xl sm:text-4xl font-semibold text-center">
        {title}
      </h1>
      <div className="flex flex-col text-sm sm:text-lg gap-5">
        <p>{description}</p>
        <p>
          The&nbsp;
          <b>Dignified Jobs Accelerator 2025 - Circular Innovation Cohort</b>
          &nbsp; brings together a passionate cohort of impact entrepreneurs who
          are building innovations and creating jobs in the waste management and
          circularity sector. The following 61 SMEs selected for the program are
          specifically focused on plastics, textiles & fashion value chain,
          creating circular solutions and social impact.
        </p>
        <p>
          Global Technology Interface (GTI) will help you leverage a global
          network of over 800 members to access innovation calls, market
          opportunities and events relevant to your business.
        </p>
      </div>

      <div className="relative w-full">
        <img
          src={companiesListRedirect}
          alt="Selected Companies"
          className="w-full object-cover rounded-2xl h-96 sm:h-full"
        />
        <Link
          to={COHORT_COMPANIES}
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-2xl cursor-pointer"
        >
          <div className="pl-8">
            <h1 className="text-white text-xl sm:text-4xl font-bold sm:w-1/2">
              Selected companies in Dignified Jobs Accelerator Circular
              Innovation Cohort 2025
            </h1>
            <p className="text-white text-xl font-bold">
              Click here to view list of Selected Companies
              <p className="text-4xl">&rarr;</p>
            </p>
          </div>
        </Link>
      </div>

      {updates?.length && (
        <div className="flex flex-col gap-5 mx-auto px-4 w-full">
          <h3 className="text-GTI-BLUE-default text-3xl font-semibold text-left">
            Announcements
          </h3>
          <div className="grid sm:grid-cols-3 justify-center gap-10 overflow-x-auto pb-6 snap-x">
            {updates.map((update) => (
              <div
                key={update.id}
                className="flex-none w-full rounded-lg overflow-hidden snap-center p-5"
              >
                <a
                  href={update?.link ? update?.link : "#"}
                  className={update?.link ? "cursor-pointer" : ""}
                >
                  <div className="h-full flex flex-col">
                    <div className="relative h-64">
                      <img
                        src={update.imageUrl}
                        alt="Waste management"
                        className="w-full h-full rounded-lg"
                      />
                    </div>
                    <div className="mt-4">
                      <p className="bg-GTI-BLUE-default text-white p-2 pl-4 pr-4 rounded-2xl w-fit">
                        {update.type}
                      </p>
                      <p className="text-sm mt-2">{update.description}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CohortDetails;
