export const companies = [
  {
    _id: {
      $oid: "6728b539451c761dfaf37c26",
    },
    fullName: "Rahul Nainani",
    email: "info@recircle.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Swachh Sustainable Solutions Private Limited",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730721081243-Copyofrecirclecr051.png",
      description:
        "Founded in 2016 by Rahul Nainani & Gurashish Singh Sahni, ReCircle is a resource recovery clean-tech innovator working towards a circular future. ReCircle empowers consumers and brands to divert waste from landfills and oceans and back into the economy to be recycled, reused or repurposed. ReCircle has diverted over 169,000 MT (equivalent to the weight of 28,166 elephants) of waste from landfills and our oceans across 270 cities & towns in India with the help of 45+ processing partners who have a pan-India network of 400 collection partners, all while impacting the lives of over 3100 informal waste workers or Safai Saathis.  \n\nAs a technology-enabled enterprise, ReCircle enables transparency, traceability, and accountability in the resource value chain to promote sustainable production and resource consumption. To achieve its mission, ReCircle has instituted flagship initiatives such as ClimaOne (a revolutionary tech-enabled platform designed to transform the informal waste supply chain), Plastic EPR Service, Plastic-Neutral programs, Project Extra Life (a textile waste management program) and ground-level community collection drives. ReCircle closed its pre-series A fundraise led by Flipkart Ventures, 3i Partners, Acumen Fund Inc and a pool of HNI investors in 2023 & another round with Mumbai Angels & Venture Catalyst in 2024. For more information, visit: https://recircle.in/ ",
    },
    productDetails: {
      _id: {
        $oid: "6728b539451c761dfaf37c2c",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "67078baf087591aa67b73c9e",
    },
    fullName: "Aadi Sustainability Solutions Pvt. Ltd.",
    email: "parvinder@recyclr.org",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Aadi Sustainability Solutions Pvt. Ltd. ",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1728547758891-Screenshot20240322195227removebgpreview.png",
      description:
        "India leading Pre consumer textiles waste collection company, building India's first EPR ready Zero waste, Waste to Denim Garments manufacturing company. ",
    },
    productDetails: {
      _id: {
        $oid: "67078bb8087591aa67b73ca4",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6728c54f451c761dfaf37e64",
    },
    fullName: "NANDITHA SULUR",
    email: "nanditha@agowig.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Agowig",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730725199682-agowiglogoblack.png",
      description:
        "We are AGOWIG, A Group Of Weavers In Gottigere, Bangalore, India.\nAgowig creates new age job opportunities for young handweavers mostly women, which keep them happy, not only financially and emotionally but in all other aspects. Our aim is also, finding out how different sustainable yarns work on the loom and how they play with each other to create beautiful fabrics.  We believe in making low carbon and water footprint fabrics that do not drain the earth of its resources, prevent landfills and at the same time keep the systems and traditions of handweaving alive. \nHeaded by Nanditha Sulur, a textile engineer and a textile designer and backed by a team of skilled dedicated passionate weavers, we have serviced many national and international client orders in the past. We look forward to continuing the good work and servicing many more orders to come.\n",
    },
    productDetails: {
      _id: {
        $oid: "6728c550451c761dfaf37e6a",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "67349cc9fa6e68ddfb5b972f",
    },
    fullName: "Sujata Pawar",
    email: "sujata@myavni.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Avni Wellness",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731501257246-NEWLOGOWebsite.png",
      description:
        "Avni Wellness is a holistic women’s wellness consumer brand solving for different life stages from menarche to menopause. We have a range of science backed, clean label products to take care of nutrition and hygiene requirements.",
    },
    productDetails: {
      _id: {
        $oid: "67349ccafa6e68ddfb5b9735",
      },
    },
    sector: ["Plastic Waste"],
    areaOfWorkUnderSector: ["Other"],
  },
  {
    _id: {
      $oid: "670365ab087591aa67b6d076",
    },
    fullName: "Divya Hegde",
    email: "divya@baeru.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Baeru",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1728275883282-BaeruLogo.png",
      description: "Ocean Plastics | Circular Economy | Coastal Livelihoods ",
    },
    productDetails: {
      _id: {
        $oid: "670365ac087591aa67b6d07c",
      },
    },
    sector: ["Plastic Waste", "Textile Waste", "Solid Waste"],
    areaOfWorkUnderSector: ["Waste collection", "Waste segregation", "Other"],
  },
  {
    _id: {
      $oid: "6734dc72fa6e68ddfb5ba1ef",
    },
    fullName: "Nidhi Joshi",
    email: "Nidhi@banofileather.com",
    userType: "DISPLAYER",
    productCount: 0,
    opportunityCount: 0,
    companyDetails: {
      name: "Atma Leather ",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731517553635-BanofiLeatherLogo.png",
      description:
        "Banofi is a material science company making plant-based material from banana crop waste.",
    },
    sector: ["Alternate Materials"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "671e6b56e2074419bc907c6f",
    },
    fullName: "Babulal Shiradana",
    email: "getplasticrecycling@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Bestool Manufacturing Pvt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730046806655-GPREdited.png",
      description: "We make Machines that recycle plastic",
    },
    productDetails: {
      _id: {
        $oid: "671e6b57e2074419bc907c75",
      },
    },
    sector: ["Plastic Waste", "Solid Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6730a947fa6e68ddfb5b278f",
    },
    fullName: "shamil salam m",
    email: "biodiveindia@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "biodive green india pvt. Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731242311336-logo.png",
      description:
        "Biodive Green India Pvt. Ltd. is a groundbreaking company dedicated to transforming plastic waste and industrial byproducts into eco-friendly construction materials. Utilizing our patented hybrid production technology, we create durable roofing tiles, paving blocks, and wall bricks that meet the highest industry standards. Our mission extends beyond environmental sustainability—we empower rural women, collaborate with local communities and schools, and restore natural water bodies by removing waste. At Biodive, we strive to build a greener planet while driving economic development and uplifting marginalized communities across India.",
    },
    productDetails: {
      _id: {
        $oid: "6730a94bfa6e68ddfb5b2796",
      },
    },
    sector: ["Plastic Waste", "Textile Waste", "Solid Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "673400d8fa6e68ddfb5b7f06",
    },
    fullName: "Vinod Madhubhai Nindrojiya",
    email: "vinodnindrojiyabrc@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Bombay Recycling Concern LLP ",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731461336038-04.Brclogo78.png",
      description:
        "We are a post-consumer textile waste management organization based in Mumbai. We assist,connecting and empowering the used clothes recyclers community (Devipujak community). We are encouraging Reuse, Repair, and Reintroduction of used clothes to poor needy people through recycler community channels in urban and rural areas.",
    },
    productDetails: {
      _id: {
        $oid: "673400d9fa6e68ddfb5b7f0c",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6734f599fa6e68ddfb5ba6c6",
    },
    fullName: "Supriya Shirsat Satam",
    email: "supriyas@foretstore.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "FOReT",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731523992982-WebsiteLogoRegisteredsymbol.png",
      description:
        "FOReT® is a unique fashion brand reshaping the landscape of style through social and climate-conscious design. By harnessing the potential of eco-friendly materials and upcycled agricultural waste and such as cork, banana bark, and plant-based leathers, FOReT® crafts accessories that not only reduce environmental impact but also foster community resilience. Our commitment to a circular economy and our collaborations with local artisans create meaningful employment, revitalize traditional skills, and champion fair labor practices. With recognition from the PETA Vegan Fashion Award and features in premier fashion magazines and collaboration with key retail channel partners, FOReT® is creating sustainable change for a stylish, responsible future.",
    },
    productDetails: {
      _id: {
        $oid: "6734f5a2fa6e68ddfb5ba6cc",
      },
    },
    sector: ["Alternate Materials"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "6735cd4dfa6e68ddfb5bc40c",
    },
    fullName: "Chirag M G",
    email: "chirag@sunbirdstraws.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Brown Reed Agri Waste Innovations",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731579212554-SunbirdLogo.png",
      description:
        "Sunbird Straws is the world's first multi layered drinking straws made from fallen coconut leaves that otherwise gets burnt in open kitchens and landfills. We have converted it into a resource, and we do this while empowering women across rural India",
    },
    productDetails: {
      _id: {
        $oid: "6735cd54fa6e68ddfb5bc413",
      },
    },
    sector: ["Other", "Agricultural Waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "6720d2a7e2074419bc90b235",
    },
    fullName: "BUTTR Sustainable Papers",
    email: "buttrpaper@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "BUTTR Sustainable Papers",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730204327114-ButtrSymbol.png",
      description:
        "BUTTR Sustainable Papers are 100 percent recycled uber-smooth paper sheets precisely cut for high-speed printing and compatible with all printers. Each ream of 500 sheets is perfect for writing,\nscanning, photocopying, and printing. ",
    },
    productDetails: {
      _id: {
        $oid: "6720d2a9e2074419bc90b23b",
      },
    },
    sector: ["Other", "Cigarette Butts"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "6718b00be2074419bc8feee8",
    },
    fullName: "Rishabh Patel",
    email: "rishabh@dumpinbin.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Dump In Bin",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1729671179638-black1.PNG",
      description: "Innovative recycling and waste management company",
    },
    productDetails: {
      _id: {
        $oid: "6718b00ce2074419bc8feeee",
      },
    },
    sector: ["Plastic Waste", "Solid Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "672f2b5bfa6e68ddfb5b04b3",
    },
    fullName: "Ajit Kumar Pallai",
    email: "ajit.pallai@ecosaathi.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Eco Saathi Green India Pvt. Ltd.",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731144539362-EcoSaathiLogo.png",
      description:
        "We are a sustainable products-based venture dealing with variety of eco-friendly products from Bamboo, Neem Wood, Crop Residue, & many other products. We are fully committed to create a sustainable lifestyle for our lovable consumers. The main objective of starting this initiative is to reduce plastic and air pollution as a whole. We also engage local artisans and farmers so as to make their lifestyle better and able to create a livelihood mission.",
    },
    productDetails: {
      _id: {
        $oid: "672f2b5cfa6e68ddfb5b04b9",
      },
    },
    sector: ["Plastic Waste", "Alternate Materials"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "670e0aa8087591aa67b7ce6b",
    },
    fullName: "Nandan Bhat",
    email: "nandan@ecokaari.org",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "EcoKaari Private Limited ",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1728973480618-G1.png",
      description:
        "Upcycled-Handcrafted Products made from Waste Plastic using Charkha and Handloom",
    },
    productDetails: {
      _id: {
        $oid: "670e0aaa087591aa67b7ce71",
      },
    },
    sector: ["Plastic Waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "67333159fa6e68ddfb5b6ad3",
    },
    fullName: "Manojj Natarrajan",
    email: "manojj@goenvitechnologies.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Goenvi Technologies Pvt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731408217378-Goenvilogo3d.png",
      description:
        "Goenvi offers patented chemical recycling solutions to mitigate climate change by transforming hydrocarbon wastes into carbon removal or avoidance products. Goenvi can process plastics, MSW Refuse and Agriwaste",
    },
    productDetails: {
      _id: {
        $oid: "6733315afa6e68ddfb5b6ada",
      },
    },
    sector: [
      "Plastic Waste",
      "Textile Waste",
      "Solid Waste",
      "Other",
      "Agriwaste",
      "MSW RDF",
    ],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "670fc97b35967da1a8861b5a",
    },
    fullName: "Gayatri Varun",
    email: "gayatri.varun@greenhermitage.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "NIKTRI ENDEAVOURS OPC PRIVATE LIMITED",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1729087867378-GHLogo.png",
      description:
        "Our Startup is working on world-class sustainable lifestyle products made out of plant-based leather. We are one of the only StartUps in India to work with the largest plant-based leather portfolio. We are not only a commercially viable brand but also a high on environmental sustainability and social impact startup. We work with community based artisans to make world class luxurious products to transition Indian urban consumers to conscious products.",
    },
    productDetails: {
      _id: {
        $oid: "670fc9826799c1bb9816c9a2",
      },
    },
    sector: ["Textile Waste", "Solid Waste", "Alternate Materials"],
    areaOfWorkUnderSector: ["Other"],
  },
  {
    _id: {
      $oid: "672c574d451c761dfaf3d423",
    },
    fullName: "Vishnusankar Chandran",
    email: "greenowillwastemanagement@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Greenowill Waste Management",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730959181479-Logo.png",
      description:
        "Greenowill Waste Management is a trailblazing social enterprise transforming waste challenges in tier 3 cities. Since 2021, we’ve partnered with the Srivilliputhur ULB to deliver impactful, sustainable waste solutions—door-to-door collection, effective segregation, and community cleanup drives.\n\nDriven by our commitment to uplift communities, Greenowill provides fair-wage employment with EPF and ESI benefits, advancing social equity and financial stability. Our vision includes a cutting-edge Material Recovery Facility (MRF) and recycling for flexible plastics, fueling India’s circular economy. Through municipal contracts, industry partnerships, and eco-awareness programs, we’re shaping cleaner, resilient communities.\n\nGreenowill: A Will to Go Green, Committed to Change.",
    },
    productDetails: {
      _id: {
        $oid: "672c574f451c761dfaf3d429",
      },
    },
    sector: ["Plastic Waste", "Textile Waste", "Solid Waste", "E-waste"],
    areaOfWorkUnderSector: ["Waste collection", "Waste segregation"],
  },
  {
    _id: {
      $oid: "670e9ede087591aa67b7e0e8",
    },
    fullName: "Harsha Puthusserry",
    email: "harshap695@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "IRAAH LOOM INTERNATIONAL PVT LTD",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1729011422328-Untitleddesign93.png",
      description:
        "Iraaloom is a startup dedicated to promoting sustainable, eco-friendly, and handmade products crafted by traditional artisans across India. Through our AI-driven platform, we provide transparency by enabling customers to trace the origin and authenticity of each product, ensuring ethical sourcing and fair compensation for artisans. Our innovation connects local craftspeople to a global market, preserving cultural heritage while addressing the growing demand for sustainable, conscious gifting and packaging solutions. By leveraging technology, Iraaloom enhances the artisan supply chain, supporting livelihoods and fostering sustainable economic growth in rural and informal sectors.",
    },
    productDetails: {
      _id: {
        $oid: "670e9edf087591aa67b7e0ee",
      },
    },
    sector: ["Other", "Conscious Gifting"],
    areaOfWorkUnderSector: ["Other"],
  },
  {
    _id: {
      $oid: "67303fd0fa6e68ddfb5b1c02",
    },
    fullName: "Rajneesh Kumar Patel",
    email: "rajneesh@irefill.in",
    userType: "DISPLAYER",
    productCount: 2,
    opportunityCount: 0,
    companyDetails: {
      name: "iRefill",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731215311849-iRefillLogo.png",
      description:
        "Tackling the plastic waste problem by developing IoT-connected dispensing solutions for FMCG products, enabling businesses to sell and consumers to purchase products without generating packaging waste.",
    },
    productDetails: {
      _id: {
        $oid: "67303fd4fa6e68ddfb5b1c09",
      },
    },
    sector: ["Plastic Waste", "Solid Waste", "Other", "Retail Innovation"],
    areaOfWorkUnderSector: ["Other"],
  },
  {
    _id: {
      $oid: "673521a6fa6e68ddfb5baaa3",
    },
    fullName: "Gautam Malik",
    email: "gautam@jaggery.co",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Jaggery: Reimagining Waste",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731535269987-JaggeryLogoHorizontaltransparent.png",
      description:
        "Jaggery is a purpose-driven social enterprise redefining fashion by upcycling waste into stylish, sustainable products. We champion an inclusive circular economy where waste is transformed into value by empowering artisans and waste workers.",
    },
    productDetails: {
      _id: {
        $oid: "673521b4fa6e68ddfb5baaaa",
      },
    },
    sector: [
      "Textile Waste",
      "Other",
      "Circular Economy / Sustainable Fashion",
    ],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "6732f5f9fa6e68ddfb5b61e6",
    },
    fullName: "Payal Nath",
    email: "admin@kadamhaat.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Kadam Haat Basketry Barn Pvt. Ltd. ",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731393017513-KadamHaat.png",
      description:
        "Kadam Haat is India's first full stack natural fibres brand that is built with equal focus on livelihood, planet and consumer",
    },
    productDetails: {
      _id: {
        $oid: "6732f60afa6e68ddfb5b61ed",
      },
    },
    sector: ["Alternate Materials"],
    areaOfWorkUnderSector: ["Other"],
  },
  {
    _id: {
      $oid: "6729dd88451c761dfaf397ae",
    },
    fullName: "Sayan Das",
    email: "sayan858506@gmail.com",
    userType: "DISPLAYER",
    productCount: 0,
    opportunityCount: 0,
    companyDetails: {
      name: "Kapda Karo Recycle ",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730796935855-1000065424.png",
      description:
        "Kapda Karo Recycle (KKR) is a pioneering startup founded by Sayan Das, focused on textile recycling and sustainable product development. Supported by MSME, Textiles, and UCO Bank, KKR transforms discarded yarns into innovative products like textile bricks for interior design and small-scale furniture. ",
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "67374d2bfa6e68ddfb5c0a96",
    },
    fullName: "Vijaya Krishnappa",
    email: "vijaya.k@kosha.ai",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "KOSHA.ai",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731677482845-KOSHALOGO.png",
      description:
        "KOSHA is deep tech startup on a mission to transform the fashion industry by driving sustainability and enabling circularity at every stage.",
    },
    productDetails: {
      _id: {
        $oid: "67374d61fa6e68ddfb5c0a9f",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: ["Waste segregation"],
  },
  {
    _id: {
      $oid: "6715edbae2074419bc8fa42d",
    },
    fullName: "Avnis",
    email: "contact@livnsense.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "LivNSense GreenOps Pvt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1729490362288-bgremoveLogo.png",
      description:
        "LivNSense GreenOps offers a cutting-edge AI-powered sustainability platform that uniquely integrates real-time energy optimization, carbon emission reduction, and predictive analytics. Our platform empowers industries to achieve deep-decarbonization through tailored solutions that enhance operational efficiency while driving significant cost savings. With over 160 science-based AI algorithms, LivNSense delivers actionable insights that facilitate compliance with environmental regulations, foster sustainable practices, and position organizations as leaders in the global transition to a greener future.",
    },
    productDetails: {
      _id: {
        $oid: "6715edbbe2074419bc8fa433",
      },
    },
    sector: [
      "Plastic Waste",
      "Textile Waste",
      "Solid Waste",
      "E-waste",
      "Alternate Materials",
    ],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6728a179451c761dfaf37805",
    },
    fullName: "Mehul Shroff",
    email: "support@mangalamkalpataru.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Mangalam Kalpataru Industries LLP",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1737033110519-MangalamKalpataruLogo.png",
      description:
        "Mangalam Kalpataru Industries (MK Industries), based in Burhanpur, Madhya Pradesh, is a pioneering organization specializing in sustainable agricultural solutions. The company is known for its flagship product, Sakal Samruddhi Organic Liquid Bio-Fertilizer, which supports eco-friendly farming practices. MK Industries places a strong emphasis on recycling post-harvest banana agro-waste, turning it into value-added products like banana stem pulp. While not a core focus, their banana fiber handicraft division provides employment to over 70 rural women, reflecting their commitment to community empowerment. With ongoing developments in banana stem pulping technology and a dedicated lab for quality testing, MK Industries continues to innovate while addressing environmental challenges and supporting sustainable rural livelihoods.",
    },
    productDetails: {
      _id: {
        $oid: "6789059bbb84e4004f2589df",
      },
    },
    sector: ["Solid Waste", "Other", "Agricultural waste"],
    areaOfWorkUnderSector: ["Waste collection", "Other"],
  },
  {
    _id: {
      $oid: "673626c3fa6e68ddfb5bd555",
    },
    fullName: "Shilpi Datta Som",
    email: "shilpidatta@rediffmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "MANY FROCKS AND",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731602115452-0B36FAE6235B42C4A54DCE5E05AE9A83.png",
      description: "Kids wear design and manufacturer",
    },
    productDetails: {
      _id: {
        $oid: "673626c5fa6e68ddfb5bd55b",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "6735ce30fa6e68ddfb5bc497",
    },
    fullName: "Shambhavi Jaiswal",
    email: "shambhavijaiswal@mulyo.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Mulyo Clothing Pvt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731579439780-MulyoBrandLogo3.png",
      description:
        'Mulyo is a homegrown conscious kidswear brand that aims to promote sustainable clothing for children and the mamas while building environmental sincerity in the young minds.\n\n"MULYO" -a Sanskrit word , means values or ethics. A brand Indian at core wrapped in Japanese aesthetics of minimalism, Mulyo stands for ethical consciousness towards the wellbeing of the planet, in a world where it is increasingly important to teach and educate the very awareness since a very young age.\n\nThus the brand aspires to infuse the idea of sustainability and conscious clothing, offering a versatility of designs , that will inspire the little ones to be more experimental with their style and help them discover the truest versions of themselves with more environmental responsibility, all the while uplifting the local artisans to a global platform.',
    },
    productDetails: {
      _id: {
        $oid: "6735ce34fa6e68ddfb5bc49e",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: ["Waste collection"],
  },
  {
    _id: {
      $oid: "67343719fa6e68ddfb5b853a",
    },
    fullName: "Murthy T",
    email: "namathi.rm@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Namathi Resource Management Pvt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731475225732-Namathi.png",
      description:
        "We Namathi Resource Management Pvt Ltd, a start-up in waste management services established to provide holistic waste management service for the Solid waste generated.",
    },
    productDetails: {
      _id: {
        $oid: "6734371bfa6e68ddfb5b8540",
      },
    },
    sector: ["Plastic Waste", "Textile Waste", "Solid Waste", "E-waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6734a09bfa6e68ddfb5b9817",
    },
    fullName: "Shwet Agarwal",
    email: "northeastgranulators@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "North East Granultors",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731502235468-NegLogo.png",
      description:
        "We are a plastic waste Upcyling company, based in North East India. We are currently having proprietary technology to develop and upcycle plastic packaging waste back for packaging",
    },
    productDetails: {
      _id: {
        $oid: "6734a09efa6e68ddfb5b981e",
      },
    },
    sector: ["Plastic Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6735c8e4fa6e68ddfb5bc212",
    },
    fullName: "Northamps Global Ecosolutions Pvt Ltd",
    email: "info@northamps.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Northamps Global Ecosolutions Pvt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731578083302-logopng.png",
      description:
        "Northamps is an approved service provider by Suchitwa Mission according to G.O No: 2243/C2/2019/SM dated 02.08.2022 for Solid and Liquid Waste Management (G.O No. 1198/2016/LSGD dated 21-02-2017). We are also a designated technical consultant for the Haritha Kerala Mission under G.O No: H.K.M/A2/625/18dated 19.02.22 for Municipal Solid Waste Management. Additionally, we serve as an empaneled engineering consultant for Suchitwa Mission, tasked with preparing detailed project reports and aiding in the execution of Liquid Waste Management Projects for Local Self Government Institutions, residential, and commercial buildings (G.O No. 2220/G/2019/SM dated 22.11.2019).",
    },
    productDetails: {
      _id: {
        $oid: "6735c8e5fa6e68ddfb5bc218",
      },
    },
    sector: ["Plastic Waste", "Solid Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6721f661e2074419bc90cb3a",
    },
    fullName: "Pooja Anand",
    email: "pooja.anand@padcarelabs.com",
    userType: "DISPLAYER",
    productCount: 0,
    opportunityCount: 0,
    companyDetails: {
      name: "PadCare Labs ",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730279009576-PadCareLogo.png",
      description:
        "PadCare is dedicated to addressing two significant challenges: environmental hazards and health risks associated with sanitary waste. We provide sustainable menstrual hygiene management solutions to corporations and communities, aligning with their ESG goals while ensuring the health and hygiene of women in the workforce. With our unique patented technology and innovative business model, PadCare now serves 22 cities in India. Annually, we recycle 34 lakh pads, conserving a cumulative 138 metric tonnes of CO2e.\n",
    },
    sector: ["Solid Waste", "Other", "Sanitary waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "673caec7fa6e68ddfb5c8e06",
    },
    fullName: "Ranjan Kumar Gupta",
    email: "ranjan@paving-plus.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "RAU ECOSTONE PVT LTD",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1732030151731-PavingLogo.png",
      description:
        "Paving+ is a pioneering company that transforms unsegregated waste plastics into sustainable building materials, including paver blocks, tiles, and boards. Utilizing patented technology, Paving+ leads the way in recycling and upcycling to create eco-friendly, high-performance products.",
    },
    productDetails: {
      _id: {
        $oid: "673caecafa6e68ddfb5c8e0c",
      },
    },
    sector: ["Plastic Waste", "Alternate Materials"],
    areaOfWorkUnderSector: [
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6718d450e2074419bc8ff468",
    },
    fullName: "Shshank Shrivastava",
    email: "shahank.sri333@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Planterra banana fiber Pvt. Ltd.",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1729680463925-logofileweb.png",
      description:
        "Planterra Banana Fiber Pvt. Ltd. is a sustainability-driven company focused on creating eco-friendly, innovative materials derived from agricultural waste, specifically banana stems. Under the brand name Banhide, we produce premium-quality banana-based imitation leather, offering a sustainable alternative to traditional leather and synthetic materials. Our mission is to reduce environmental impact while providing durable and versatile materials for various industries, including fashion, footwear, and accessories. By utilizing agricultural waste, we not only minimize resource use but also contribute to rural employment and the circular economy",
    },
    productDetails: {
      _id: {
        $oid: "6718d452e2074419bc8ff46e",
      },
    },
    sector: ["Alternate Materials"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "672b37ca451c761dfaf3b810",
    },
    fullName: "Surbhi Agarwal",
    email: "surbhi@pravaahindia.com",
    userType: "SCOUTER",
    productCount: 0,
    opportunityCount: 1,
    companyDetails: {
      name: "Pravaah India",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730885578535-LOGOSMALL.png",
      description:
        "ravaah offers eco-friendly, plastic-free home, living and custom gifting solutions for conscious consumers, primarily educated millennials and urban families, who value sustainability and quality. Our products provide a stylish, guilt-free alternative to conventional home goods, making sustainable living easy and luxurious.",
    },
    sector: ["Alternate Materials"],
    areaOfWorkUnderSector: ["Other"],
  },
  {
    _id: {
      $oid: "67330e1ffa6e68ddfb5b65c9",
    },
    fullName: "Rahul Juware",
    email: "rahul@3rsolutions.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "R3RR Eco Solutions Private Limited",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731399199769-Finallogoregisteres02.png",
      description:
        "3R Solutions provide packaging reuse solution that costs less* than making new containers, reduces plastic waste, and ensures legal compliance.",
    },
    productDetails: {
      _id: {
        $oid: "67330e20fa6e68ddfb5b65cf",
      },
    },
    sector: ["Plastic Waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "672bac37451c761dfaf3c4e1",
    },
    fullName: "Namrata Soni",
    email: "rayush2610@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Rayush Natural Fiber Pvt Ltd.",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730915383674-RayushNaturalFiberlogo.png",
      description:
        " At Rayush Natural Fibers, we're tackling climate change and global warming uniquely. Our sustainable Aromatic outdoor home textiles, infused with antidepressant fragrance and mosquito repellent properties, offer comfort without electricity. They also promote aromatic crop cultivation, combat soil erosion, and reduce chemical usage for farmers. Benefits include zero power consumption, job creation, and reduced heat generation. Join us in making a positive impact! these home textiles are inspired by ancient natural cooling practices, crafted from aromatic crops to bring the essence of nature into the modern lifestyle.",
    },
    productDetails: {
      _id: {
        $oid: "672bac8f451c761dfaf3c4ed",
      },
    },
    sector: ["Textile Waste", "Solid Waste", "Other", "Agri waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "67378bd6fa6e68ddfb5c1771",
    },
    fullName: "jai kant",
    email: "footwearcomfy@gmail.com",
    userType: "SCOUTER",
    productCount: 0,
    opportunityCount: 0,
    companyDetails: {
      name: "RDRKD RUBBER AND PLASTIC INDUSTRY PVT. LTD",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731693526318-rdrkdlogo.PNG",
      description:
        "Our company is a technology-driven enterprise revolutionizing waste management and sustainable manufacturing. Established in 2018, we specialize in transforming discarded plastics and rubber into innovative raw materials for footwear production and house hold plastic based items. By integrating IoT-enabled machines, AI-powered processes, and a mobile app platform, we connect junk pickers, dealers, and traditional mochi artisans in a streamlined circular economy. Our mission is to reduce environmental pollution while empowering local communities with sustainable livelihoods. With a robust focus on technology and innovation, we create high-quality, eco-friendly products, driving both social impact and industrial efficiency.",
    },
    sector: [
      "Plastic Waste",
      "Textile Waste",
      "Solid Waste",
      "Alternate Materials",
    ],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "67363630fa6e68ddfb5bd7d6",
    },
    fullName: "Rechain Technologies Private Limited",
    email: "team@greenaadhaar.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "GREEN AADHAAR",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731606064162-greenaadhaarlogobd9a7a22.png",
      description:
        "GREEN AADHAAR is a SaaS platform providing real-time monitoring, route optimization, and workforce management for sustainable waste operations from collection to processing.",
    },
    productDetails: {
      _id: {
        $oid: "67363646fa6e68ddfb5bd7de",
      },
    },
    sector: [
      "Plastic Waste",
      "Textile Waste",
      "Solid Waste",
      "E-waste",
      "Alternate Materials",
      "Other",
      "C&D waste",
    ],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
      "Other",
    ],
  },
  {
    _id: {
      $oid: "67370cc6fa6e68ddfb5bfbbe",
    },
    fullName: "Archish Kansal",
    email: "info@respunindia.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Respun",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731660997992-PrimeryLogoofRespun.png",
      description: "Collections, sorting and recycling of textile wastes",
    },
    productDetails: {
      _id: {
        $oid: "67370cedfa6e68ddfb5bfbc6",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "671b3943e2074419bc903669",
    },
    fullName: "Tharun Jagateri",
    email: "tharun@enviu.org",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Enviu Venture Lab India Pvt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1729837379286-retexlogo.png",
      description:
        "Enviu Venture Labs is a venture building studio in the textile waste space and we want to showcase Retex, that is rescuing end-of-life hospitality industry textile waste from going to a landfill by converting it back into bed and bath linens",
    },
    productDetails: {
      _id: {
        $oid: "671b3945e2074419bc90366f",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "672caa2d8037f1b163d54d19",
    },
    fullName: "AdityaSrinivas k",
    email: "team@roha.bio",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Roha 369 biotech PVt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730980397357-ROHADark11.png",
      description:
        "A certified biodegradable protective pacakging material made by upcycling agro waste and mushroom mycelium which is a perfect alternative to thermocol.",
    },
    productDetails: {
      _id: {
        $oid: "672caa2e8037f1b163d54d1f",
      },
    },
    sector: ["Solid Waste", "Alternate Materials"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "6739c836fa6e68ddfb5c4891",
    },
    fullName: "Kunwar Rahul Singh",
    email: "rahul@ourreverse.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Rudraya wastech Private limited",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731840054434-REVERSElogo.png",
      description:
        "REVERSE is a cleantech platform pioneering the sustainable reuse of packaging materials, focusing on glass and rigid plastic containers. We provide end-to-end solutions, integrating tracking, collection, segregation, sanitization, and reverse logistics to reintegrate reusable packaging back into supply chains. By leveraging advanced technologies, including blockchain for transparency and AI for automation, we address India’s growing waste management challenges while helping brands achieve sustainability through reuse. Resulting in organizations contributing to a cleaner tomorrow, EPR compliance, ESG reporting, and packaging emission set-off. ",
    },
    productDetails: {
      _id: {
        $oid: "6739c837fa6e68ddfb5c4897",
      },
    },
    sector: ["Plastic Waste", "Solid Waste", "Other", "Glass waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
      "Other",
    ],
  },
  {
    _id: {
      $oid: "6736031afa6e68ddfb5bce07",
    },
    fullName: "Aditya Shukla",
    email: "aditya.shukla@saltech.co.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Saltech Design Labs Private Limited",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731592986624-Asset6.png",
      description:
        "Our company creates circularity by deploying a patented advance recycling technology for transforming mix Single-Use Plastics along with Industrial, Construction & Demolition Waste into polymer composite building materials with high-performance at an affordable price that stay in the economy longer and prevent carbon emissions associated with its disposal or end of life systems while providing sustainable alternative to traditional building materials such as cement, concrete, plastics etc. It hold 9+ Product Certifications, 10+ IPs & 12+ Awards (including 3 National Awards SBM Urban, SBM Grameen, GHTC India under PMAY by Govt of India).",
    },
    productDetails: {
      _id: {
        $oid: "6736031efa6e68ddfb5bce0d",
      },
    },
    sector: ["Plastic Waste", "Solid Waste", "Alternate Materials"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "670d495c087591aa67b7bd78",
    },
    fullName: "Mukul Chhabra",
    email: "mukul@scrapuncle.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "ScrapUncle (Swapeco Solutions Pvt. Ltd.)",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1728923996590-ScrapUncleLogoRGBTransparentq3sdnsj5x8zcipjtr0565gfw8hups4gc9pujaaqcrw.png",
      description:
        "ScrapUncle is an App/Website where users can schedule a doorstep pickup for their Electronic Waste and other Recyclables and get paid for the same. ",
    },
    productDetails: {
      _id: {
        $oid: "670d495e087591aa67b7bd7e",
      },
    },
    sector: ["Plastic Waste", "Textile Waste", "Solid Waste", "E-waste"],
    areaOfWorkUnderSector: ["Waste collection"],
  },
  {
    _id: {
      $oid: "67342328fa6e68ddfb5b8223",
    },
    fullName: "SHRIGOPAL JAGTAP",
    email: "shrijagtap07@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "SHRISHTI WASTE MANAGEMENT SERVICES PRIVATE LIMITED",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731470119946-SWMSLogoFinalPDFfromCorel1.png",
      description:
        "Shrishti Waste Management Services Pvt. Ltd offers variety of work including Door to Door Awareness & Survey, Waste Segregation & Collection, Training & Capacity Building Programs for Waste Pickers & other Stakeholders, Livelihood Promotion of Waste Picker Community, Composting and Material Recovery Facilities for Dry/Plastic Waste Recycling",
    },
    productDetails: {
      _id: {
        $oid: "6734232bfa6e68ddfb5b8229",
      },
    },
    sector: ["Plastic Waste", "Solid Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6735941ffa6e68ddfb5bb80c",
    },
    fullName: "Victoria Joslin Dsouza",
    email: "victoria@swachhaecosolutions.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Swachha Eco Solutions Private Limited",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731564575181-LogoNew.png",
      description:
        "Swachha Eco Solutions Pvt Ltd is an Integrated Waste Management Organization incorporated in the year 2017, with constant innovation, advanced management systems, and the highest standards of customer service, an “Integrated Solid Waste Management System \nApproach” capable of managing all recyclable / non- recyclable waste. \n",
    },
    productDetails: {
      _id: {
        $oid: "67359506fa6e68ddfb5bb822",
      },
    },
    sector: ["Plastic Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "672f8747fa6e68ddfb5b0d44",
    },
    fullName: "Akansha Akansha",
    email: "akanshasingh1690@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Swayambhu Innovative Solutions Pvt Ltd",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731168070923-logo.png",
      description:
        "We are a plastic waste management company and registered recycler under Central Pollution Control Board working in collaboration with several municipalities, railways and local waste pickers to provide them dignified Green jobs and bring them in the formal sector.",
    },
    productDetails: {
      _id: {
        $oid: "672f8748fa6e68ddfb5b0d4a",
      },
    },
    sector: ["Plastic Waste", "Other", "Wet waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "670e5943087591aa67b7d8a0",
    },
    fullName: "Dr Aligina Anvitha Sudheshna",
    email: "swizuka@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Swizuka Recyweave Textile LLP",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1728993603596-SWIZUKARECYWEAVETEXTILELLP.png",
      description:
        "Swizuka RecyWeave Textile LLP is a sustainability-driven company founded by two female entrepreneurs in June 2024. We specialize in transforming textile waste into valuable, eco-friendly products, fostering a circular economy in the textile industry. Our operations empower rural artisans, by providing them with employment opportunities while promoting environmental conservation. Through innovative recycling and upcycling techniques, we aim to reduce the textile industry's environmental footprint and promote responsible consumption. Our product line includes recycled fiber, upcycled home decor items, and sustainable made-ups that align with green goals of businesses.",
    },
    productDetails: {
      _id: {
        $oid: "670e5944087591aa67b7d8a6",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "673717e2fa6e68ddfb5bfe36",
    },
    fullName: "Siddarth Amaravathi",
    email: "siddarth@tausphere.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Tausphere Private Limited",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731663841868-TauSphere.png",
      description:
        "Developing sustainable Biomaterials for the Textile Industry",
    },
    productDetails: {
      _id: {
        $oid: "673717ebfa6e68ddfb5bfe3d",
      },
    },
    sector: ["Alternate Materials"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "673732e1fa6e68ddfb5c06da",
    },
    fullName: "Suhas Ramegowda",
    email: "suhas@thegooddoll.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "The Good Doll",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731670753549-ooty640x640px.png",
      description:
        "Manufactures fabric dolls using upcycled pre consumer textile waste and generates dignified jobs women from rural & indigenous communities of The Nilgiris mountains. ",
    },
    productDetails: {
      _id: {
        $oid: "673732eafa6e68ddfb5c06e1",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "6706465d087591aa67b71ecb",
    },
    fullName: "Mayank Singh",
    email: "hello@tooused.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Tooused",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1728464477443-Toousedappicon500x500.png",
      description:
        "A platform designed to help households declutter their wardrobes responsibly by selling, swapping, or donating used and unused clothes.",
    },
    productDetails: {
      _id: {
        $oid: "6706465e087591aa67b71ed1",
      },
    },
    sector: ["Textile Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "672ca77c451c761dfaf3e057",
    },
    fullName: "Varun Jhunjhunwala",
    email: "support@truhemplifestyle.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Truhemp Lifestyle",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1730979708137-truhemplogo.png",
      description:
        "TruHemp designs and develops sustainable products from hemp fibers, including handloom fabrics crafted by local artisans, handmade goods, and powerloom textiles. Our offerings range from clothing to bags and accessories, and we support brands in launching hemp lines through private labeling. We’re dedicated to promoting industrial hemp as a sustainable textile alternative.",
    },
    productDetails: {
      _id: {
        $oid: "672ca77c451c761dfaf3e05d",
      },
    },
    sector: ["Plastic Waste", "Textile Waste", "Alternate Materials"],
    areaOfWorkUnderSector: ["Other"],
  },
  {
    _id: {
      $oid: "67361e7cfa6e68ddfb5bd359",
    },
    fullName: "Praveen Crasta",
    email: "praveen@ui.ltd",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Unified Intelligence Pvt. Ltd.",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731599996349-UILOGOPNG.png",
      description:
        "Based on honeycomb technology, we make high performance construction panels from Single use plastic waste, Paper waste and Textile waste. ",
    },
    productDetails: {
      _id: {
        $oid: "67361ea0fa6e68ddfb5bd380",
      },
    },
    sector: ["Plastic Waste"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
  {
    _id: {
      $oid: "6736dee1fa6e68ddfb5bede7",
    },
    fullName: "Vikas Shetty",
    email: "info@vapac.in",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "VAPAC Bio Plastics Private Limited",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1731649249084-vapachighresolutionlogocolorontransparentbackground.png",
      description:
        "VAPAC Bio-Plastics, where innovation meets sustainability in manufacturing biodegradable and compostable plastic packaging solutions. We specialise in creating eco-friendly alternatives to traditional plastics, offering a wide range of biodegradable and compostable packaging products designed to meet the needs of modern consumers and businesses alike.\n\nAs a pioneer in sustainable packaging solutions, we are proud to have developed India's first transparent bio-poly-fillers dedicated to integral packaging applications. This achievement underscores our commitment to innovation and sustainability in the 63% market share of integral plastics industry, providing a clear alternative to conventional packaging materials.\n\nAt VAPAC Bio-Plastics, we go beyond standard offerings by collaborating closely with our customers to develop tailor-made products that meet specific demands. Whether you require biodegradable and compostable packaging bags or custom packaging solutions, our team of experts is dedicated to delivering eco-friendly products that align with your unique requirements.\n",
    },
    productDetails: {
      _id: {
        $oid: "6736dee8fa6e68ddfb5beded",
      },
    },
    sector: ["Alternate Materials"],
    areaOfWorkUnderSector: ["Other"],
  },
  {
    _id: {
      $oid: "670767fe087591aa67b738f1",
    },
    fullName: "Ann Anra",
    email: "info@indiawasted.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "Wasted 360 Solutions",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1728538622237-WastedLogoPNGcircle.png",
      description:
        "Making sustainable action easy and fun with accessible drop off points, exhaustive collection list (20+ categories) while creating dignified jobs for under served communities in circular economy ",
    },
    productDetails: {
      _id: {
        $oid: "67076801087591aa67b738f7",
      },
    },
    sector: [
      "Plastic Waste",
      "Textile Waste",
      "Solid Waste",
      "Other",
      "Crays",
      "Tablet strips",
      "Footwear",
      "Handbags",
      "Decor",
      "Furniture",
    ],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste processing or waste to value",
    ],
  },
  {
    _id: {
      $oid: "6718b6f8e2074419bc8ff055",
    },
    fullName: "Uma Sharma",
    email: "sharmauma981@gmail.com",
    userType: "DISPLAYER",
    productCount: 1,
    opportunityCount: 0,
    companyDetails: {
      name: "WomenasticCO",
      logo: "https://prod-gti.s3.ap-south-1.amazonaws.com/companies_logos/1729672952339-logonobackground.png",
      description:
        "Converting plastic waste into 3d printer filament by using sustainable and eco friendly polymer quenching machine that uses only 5Kw of solar power to run. ",
    },
    productDetails: {
      _id: {
        $oid: "6718b6fae2074419bc8ff05b",
      },
    },
    sector: ["Plastic Waste", "Alternate Materials"],
    areaOfWorkUnderSector: ["Waste processing or waste to value"],
  },
];

const pendingCompanies = [
  {
    email: "stefano@iwasasari.com",
    company: "2nd Innings Handicrafts Private Limited ( I was a Sari )",
  },
  {
    email: "contact@trashfix.in",
    company: "Trashfix Bharat Private Limited",
    sector: ["Plastic Waste"],
    areaOfWorkUnderSector: [
      "Waste collection",
      "Waste segregation",
      "Waste processing or waste to value",
      "Other",
    ],
  },
  {
    email: "gayathrin098@gmail.com",
    company: "KabadPe",
    sector: ["Plastic Waste", "Solid Waste"],
    areaOfWorkUnderSector: ["Waste collection"],
  },
];
