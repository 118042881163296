import React, { Dispatch, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import {
  ConnectionStatus,
  NONE,
  sendchatItem,
  title,
  metaData,
} from "../constants";
import test_profile from "../../assests/test/profile.jpg";
import defaultPic from "../../assests/images/default-user.svg";
import { IoSend } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getChat,
  getMoreChat,
  sendChat,
} from "../../store/actioncreators/chatactions";
import { useNavigate, useParams } from "react-router-dom";
import { getConnections } from "../../store/actioncreators/connectionactions";
import { connectionItem } from "../Modules/getData";
import { getUserData } from "../../api/user";
import { isSuccess, notify } from "../../utils";
import { useDebouncedCallback } from "../../shared/hooks/useDebounce";

const Sender = ({ image, message }: { image: string; message: string }) => {
  return (
    <div className="flex flex-row items-start justify-end my-2 w-full ">
      <p className=" font-roboto text-start rounded-xl bg-violet-400 px-4 py-2 text-white mr-3">
        {message ?? ""}
      </p>
      {
        <img
          loading="lazy"
          src={image !== NONE ? image : test_profile}
          className="rounded-full w-8 h-8 object-cover"
          alt="Sender"
        />
      }
    </div>
  );
};
const Receiver = ({ image, message }: { image: string; message: string }) => {
  return (
    <div className="flex flex-row items-start justify-start my-2 w-full ">
      {
        <img
          loading="lazy"
          src={image != NONE ? image : test_profile}
          className="rounded-full mr-3  w-8 h-8 object-cover"
          alt="Receiver"
        />
      }
      <p className=" font-roboto text-start rounded-xl bg-slate-200 px-4 py-2  text-slate-600">
        {message ? message : "Error in displaying message"}
      </p>
    </div>
  );
};

interface IChatContainer {
  chatContainerRef: any;
  senderId: string;
  receiverId: string;
  page: {
    skip: number;
    limit: number;
  };
  setPage: any;
}

const ChatContainer: React.FC<IChatContainer> = ({
  chatContainerRef,
  senderId,
  receiverId,
  page,
  setPage,
}) => {
  const dispatch = useDispatch<any>();
  const chat_data: CHAT_STATE = useSelector((state: STATE) => state.CHAT);

  const { id } = useParams();
  useEffect(() => {
    if (chat_data.CHAT_LIST.CHAT.length <= 10)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
  }, [chat_data, chatContainerRef]);

  useEffect(() => {
    dispatch(getChat(id ?? "", page.skip.toString(), page.limit.toString()));
    setPage((prev: any) => ({
      skip: prev.skip + 1,
      limit: prev.limit + 1,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getChat(id ?? "", "0", "10"));
    setPage({
      skip: 1,
      limit: 10,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleScroll = () => {
    if (chatContainerRef?.current) {
      const container = chatContainerRef.current;
      const scrollPos = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      const offset = 200;

      if (
        scrollPos <= offset &&
        scrollHeight > clientHeight &&
        page.skip * page.limit < chat_data?.CHAT_TOTAL
      ) {
        dispatch(
          getMoreChat(receiverId, page.skip.toString(), page.limit.toString())
        );
        setPage((prev: any) => ({
          skip: prev.skip + 1,
          limit: prev.limit + 1,
        }));
      }
    }
  };

  const debounceScroll = useDebouncedCallback(handleScroll, 500);

  return (
    <div
      ref={chatContainerRef}
      onScroll={debounceScroll}
      className="flex flex-col h-screen mt-20 py-2 px-20 overflow-auto scrollbar-w-1 scrollbar-track-transparent scrollbar-thumb-gray-500"
    >
      {chat_data &&
        chat_data.CHAT_LIST.CHAT.map((item: any, id: number) => {
          if (item.user._id === senderId) {
            return (
              <div key={id} className="flex flex-col">
                <Sender
                  message={item?.message}
                  image={item.company?.logo ?? defaultPic}
                />
              </div>
            );
          } else {
            return (
              <div key={id} className="flex flex-col">
                <Receiver
                  message={item?.message}
                  image={item.company?.logo ?? defaultPic}
                />
              </div>
            );
          }
        })}
    </div>
  );
};

const UserChat = ({
  receiver,
  sender,
  handleView,
}: {
  receiver?: any;
  sender: USER;
  handleView: (id: string) => void;
}) => {
  const { id } = useParams();
  const dispatch: Dispatch<any> = useDispatch();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState({
    skip: 0,
    limit: 10,
  });
  const [message, setMessage] = useState<sendchatItem>({
    message: "",
    receiver: id ?? "",
  });

  const handleSend = async () => {
    setMessage({ ...message, message: "" });
    dispatch(
      sendChat(
        {
          receiver: id ?? "",
          message: message.message,
        },
        page.skip,
        page.limit
      )
    );
    if (chatContainerRef.current) {
      setTimeout(() => {
        const container = chatContainerRef.current;
        container!.scrollTop = container!.scrollHeight;
      }, 500);
    }
  };

  return (
    <div className="flex flex-col w-full h-full justify-between relative ">
      <div className="absolute flex flex-row bg-white border-b justify-between items-center w-full border-b-slate-200 px-10 py-1">
        <div className="flex flex-row items-center justify-start my-2 w-full ">
          {
            <img
              src={receiver && receiver.logo ? receiver.logo : defaultPic}
              className="rounded-full mr-3 w-8 h-8 object-cover"
              alt={receiver?.user?.name}
            />
          }
          <p className=" font-roboto text-center  text-slate-600">
            {receiver && receiver.fullName
              ? receiver.fullName
              : "Error in displaying receiver name"}
          </p>
        </div>
      </div>
      <ChatContainer
        chatContainerRef={chatContainerRef}
        senderId={sender.id}
        receiverId={receiver?._id ?? ""}
        page={page}
        setPage={setPage}
      />
      <form
        className="flex flex-row py-2.5 border-t border-b items-center"
        onSubmit={(e) => {
          e.preventDefault();
          handleSend();
        }}
      >
        <input
          value={message.message}
          onChange={(e) => {
            setMessage({ ...message, message: e.target.value });
          }}
          type="text"
          id="floating_outlined"
          className="block px-2.5  w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none border-none outline-none  focus:ring-0 focus:border-blue-600 "
          placeholder="Type Something "
          required
        />
        <button
          className="bg-transparent border-none shadow-none rounded-full"
          type="submit"
        >
          <IoSend className="w-10 h-10 bg-GTI-BLUE-default text-white hover:bg-white hover:text-GTI-BLUE-default duration-200 p-1 mr-5 rounded-full" />
        </button>
      </form>
    </div>
  );
};

const ConnectionsCard = ({
  item: connectionData,
  handleView,
}: {
  item: any;
  handleView: (id: string) => void;
}) => {
  const item = connectionData?.sender
    ? {
        ...connectionData.sender,
        ...connectionData.senderCompany,
      }
    : {
        ...connectionData.receiver,
        ...connectionData.receiverCompany,
      };
  const handleOpen = () => {
    handleView(item._id);
  };
  return (
    <div
      onClick={() => {
        handleOpen();
      }}
      className="flex flex-row cursor-pointer items-center justify-start my-2 w-full h-full border-b border-slate-400 hover:bg-GTI-BLUE-default hover:bg-opacity-5 p-2"
    >
      {item.logo ? (
        <img
          src={item.logo}
          className="rounded-full mr-3 w-8 h-8 object-cover"
          alt={item.fullName}
        />
      ) : (
        <img
          src={defaultPic}
          className="rounded-full mr-3 w-8 h-8 object-cover"
          alt={item.fullName}
        />
      )}
      <p className=" font-roboto text-center text-slate-600">
        {item.fullName ? item.fullName : "Error in displaying message"}
      </p>
    </div>
  );
};

const Chat = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const [receiver, setReceiver] = useState<any>(null);

  const connections: CONNECTION = useSelector(
    (state: STATE) => state.CONNECTION.CONNECTION
  );
  const [search, setSearch] = useState({
    key: "",
    process: false,
  });
  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);

  const [currentChat, setCurrentChat] = useState({
    id,
    show: false,
  });
  const handleView = (id: string) => {
    navigate(`/chat/${id}`);
    setCurrentChat({ id: id, show: true });
  };

  useEffect(() => {
    if (id) {
      fetchReceiver(id);
      setCurrentChat((prev) => ({
        id,
        show: true,
      }));
    }
  }, [id]);

  useEffect(() => {
    if (id && connections?.CONNECTION_LIST?.connections?.length) {
      let found = false;
      connections.CONNECTION_LIST.connections.forEach((connection) => {
        if (connection?.receiver?._id === id || connection?.sender?._id === id)
          found = true;
      });

      if (!found) {
        navigate("/chat", { replace: true });
        setCurrentChat({ id: id, show: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections]);

  useEffect(() => {
    dispatch(getConnections(ConnectionStatus.CONNECTED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReceiver = async (id: string) => {
    const response = await getUserData(id);
    if (isSuccess(response)) {
      setReceiver({ ...response.data, ...response.data.company[0] });
    } else {
      notify("Unable to fetch data!", "error");
    }
  };

  return (
    <div
      className="flex flex-row w-full max-h-screen mt-5"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <Helmet>
        <title>{title.CHAT}</title>
        <meta name="description" key="description" content={metaData.CHAT} />
        <meta name="title" key="title" content="Chat" />
        <meta property="og:title" content="Chat" />
        <meta property="og:description" content="Chat" />
        <meta property="og:image" content={NONE} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/chat`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Chat" />
        <meta name="twitter:description" content="Chat" />
        <meta name="twitter:image" content={NONE} />
        <meta name="twitter:card" content="Chat" />
      </Helmet>
      <div className="flex flex-col w-2/6 min-w-[250px] p-5 bg-gray-100 scroll-m-1">
        <div className="flex items-center py-10 px-7">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  // fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  // clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="bg-gray-50  text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  "
              placeholder="Search Connections"
              required
              value={search.key}
              onChange={(e) => {
                setSearch({ key: e.target.value, process: true });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col overflow-auto">
          {connections.CONNECTION_LIST.connectionsCount &&
            connections.CONNECTION_LIST.connections
              .filter((item: any) => {
                if (item.receiver)
                  return item?.receiver?.fullName?.match(
                    new RegExp(search.key, "i")
                  );
                else
                  return item?.sender?.fullName?.match(
                    new RegExp(search.key, "i")
                  );
              })
              .map((item: connectionItem, id: number) => {
                return (
                  <ConnectionsCard
                    key={id}
                    item={item}
                    handleView={handleView}
                  />
                );
              })}
        </div>
      </div>
      <div className="flex w-full">
        {receiver && id && (
          <UserChat
            handleView={handleView}
            sender={currentUser}
            receiver={receiver}
          />
        )}
      </div>
    </div>
  );
};

export default Chat;
