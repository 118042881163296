export const cohortCompanySectors = [
  "Agricultural Waste",
  "Alternate Materials",
  "C&D waste",
  "Circular Economy / Sustainable Fashion",
  "Decor",
  "Fashion & Accessories",
  "Footwear",
  "Furniture",
  "E-waste",
  "Glass waste",
  "Other",
  "Plastic Waste",
  "Retail Innovation",
  "Sanitary waste",
  "Solid Waste",
  "Textile Waste",
  "Wet waste",
];

export const cohortCompanyAreaOfWorkUnderSector = [
  "Other",
  "Waste collection",
  "Waste processing or waste to value",
  "Waste segregation",
];
