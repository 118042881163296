import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  productItemFullFetched,
  productItemPartialFetched,
} from "../constants";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../../store/actioncreators/productactions";
import { spinnerLoaderStart } from "../../store/actioncreators/loaderactions";
import { ScreenSpinner } from "../utils/loader";
import RenderHTML from "../utils/RenderHTML";
import biotechnology from "../../assests/images/biotechnology.jpg";
import cleantech from "../../assests/images/cleantech.jpg";
import informationTechnology from "../../assests/images/information-technology.jpg";

const Card = ({ item }: { item: productItemFullFetched }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    dispatch(spinnerLoaderStart());
    navigate(`/product/${item._id}`, { state: { product: item } });
  };

  const getDefaultImage = (sectorName: string) => {
    if (sectorName === "Biotechnology") {
      return biotechnology;
    } else if (sectorName === "Clean Technology") {
      return cleantech;
    } else {
      return informationTechnology;
    }
  };

  const DOP = new Date(item.createdAt);

  return (
    <div
      className="product-card-main"
      onClick={() => {
        handleView();
      }}
    >
      <div className="product-card-img">
        <img
          src={
            item.image
              ? item.image
              : item.company.logo
              ? item.company.logo
              : getDefaultImage(item.sectors.name)
          }
          className="flex  w-full lg:h-56  rounded-t-lg hover:rounded-lg object-contain"
          alt={item.name}
          loading="lazy"
        />
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-xs text-slate-500 ">
          {"Posted on:"}
          {DOP.toLocaleString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-lg text-GTI-BLUE-default ">
          {item.name.length > 25
            ? item.name.substring(0, 25) + "..."
            : item.name}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto  text-xs text-slate-500 ">
          {item.description.length > 150 ? (
            <RenderHTML html={item.description.substring(0, 150) + "..."} />
          ) : (
            <RenderHTML html={item.description.substring(0, 150)} />
          )}
        </h4>
      </div>
    </div>
  );
};

const ProductList = ({
  skip,
  limit,
  secId,
  subSecId,
  search,
}: {
  skip: string;
  limit: string;
  subSecId: string;
  secId: string;
  search: string;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  const products: PRODUCTS = useSelector(
    (state: STATE) => state.PRODUCTS.PRODUCTS
  );

  const navigate = useNavigate();
  const [loadMore, setMore] = useState(false);

  useEffect(() => {
    dispatch(getProducts(skip, limit, secId, subSecId, search));
  }, [secId, subSecId, skip, limit, search]);

  return (
    <div className="flex w-full justify-center items-center px-10 flex-col">
      {spinner.SPINNER ? (
        <ScreenSpinner />
      ) : (
        <div className="product-list-main">
          {products.PRODUCTS_LIST?.products &&
            products.PRODUCTS_LIST?.products
              .filter((item: productItemPartialFetched) => {
                return subSecId !== "" ? subSecId === item.subSectorId : true;
              })
              .map((item: productItemFullFetched, id: number) => {
                return <Card item={item} key={id} />;
              })}
        </div>
      )}
    </div>
  );
};
export default ProductList;
