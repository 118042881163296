import React, { useEffect, useRef, useState } from "react";

interface AnimateCountInterface {
  targetValue: number;
  title: string;
}

const AnimateCount: React.FC<AnimateCountInterface> = ({
  targetValue,
  title,
}) => {
  const duration = 1000,
    start = 1;
  const countRef = useRef<HTMLDivElement>(null);

  const [count, setCount] = useState<number>(1);

  const animateCount = () => {
    const startTime = Date.now();
    const endTime = startTime + duration;

    const updateCount = () => {
      const now = Date.now();
      const remainingTime = Math.max(0, endTime - now);
      const delta = targetValue - start;
      const step = (delta / duration) * (duration - remainingTime);

      setCount(Math.floor(start + step));

      if (remainingTime > 0) {
        requestAnimationFrame(updateCount);
      }
    };

    requestAnimationFrame(updateCount);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateCount();
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const countRefCurrent = countRef.current;
    if (countRefCurrent) {
      observer.observe(countRefCurrent);
    }

    return () => {
      if (countRefCurrent) {
        observer.unobserve(countRefCurrent);
      }
    };
  }, []);

  return (
    <div
      className="flex flex-col justify-center items-center gap-1 w-full"
      ref={countRef}
    >
      <h1 className="text-2xl md:text-4xl font-extrabold">{count}+</h1>
      <p className="text-xs md:text-lg font-light text-center">{title}</p>
    </div>
  );
};

export default AnimateCount;
