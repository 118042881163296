import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import companiesListBanner from "../../assests/cohort/companies_list.svg";
import CompanyCard from "./CompanyCard";
import { companies } from "../../shared/cohort/companies";
import CompanyDropdownFilter from "./CompanyDropdownFilter";
import {
  cohortCompanyAreaOfWorkUnderSector,
  cohortCompanySectors,
} from "../../shared/cohort/sectors";
import gtiLogo from "../../assests/images/gti_logo.svg";

const Companies = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  // const itemsPerPage = 9;
  const defaultSector = "Sector",
    defaultSubSector = "Area of Work";

  const [searchTerm, setSearchTerm] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  // const [companiesList, setCompaniesList] = useState<any>(companies);
  const [filteredCompanies, setFilteredCompanies] = useState<any>(companies);
  const [sector, setSector] = useState<string>(defaultSector);
  const [selectedSubSector, setSelectedSubSector] =
    useState<string>(defaultSubSector);

  // useEffect(() => {
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //   setFilteredCompanies(
  //     companiesList.slice(indexOfFirstItem, indexOfLastItem)
  //   );
  // }, [currentPage]);

  useEffect(() => {
    if (
      !searchTerm &&
      sector === defaultSector &&
      selectedSubSector === defaultSubSector
    ) {
      setFilteredCompanies(companies);
      // setTotalPages(Math.ceil(companies?.length / itemsPerPage));
      // setCurrentPage(1);
      return;
    }

    let filteredCompanies = companies.filter((company) =>
      company.companyDetails.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    if (sector !== defaultSector)
      filteredCompanies = filteredCompanies.filter((company) =>
        company.sector.includes(sector)
      );
    if (selectedSubSector !== defaultSubSector)
      filteredCompanies = filteredCompanies.filter((company) =>
        company.areaOfWorkUnderSector.includes(selectedSubSector)
      );

    if (filteredCompanies?.length) {
      // const indexOfLastItem = currentPage * itemsPerPage;
      // const indexOfFirstItem = indexOfLastItem - itemsPerPage;

      // filteredCompanies = filteredCompanies.slice(
      //   indexOfFirstItem,
      //   indexOfLastItem
      // );

      setFilteredCompanies(filteredCompanies);
      // setTotalPages(Math.ceil(filteredCompanies?.length / itemsPerPage));
      // setCurrentPage(1);
    } else {
      setFilteredCompanies([]);
      // setTotalPages(0);
      // setCurrentPage(0);
    }
  }, [searchTerm, sector, selectedSubSector]);

  // const goToNextPage = () => {
  //   if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  // };
  // const goToPreviousPage = () => {
  //   if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  // };

  const title =
      "Selected companies in Dignified Jobs Accelerator Circular Innovation Cohort 2025",
    description =
      "Selected companies in Dignified Jobs Accelerator Circular Innovation Cohort 2025";

  return (
    <div className="flex flex-col gap-10 w-full">
      <Helmet>
        <title>{title}</title>
        <meta name="description" key="description" content={description} />
        <meta name="title" key="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={companiesListBanner ? companiesListBanner : gtiLogo}
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/dignified-jobs-accelerator-circular-innovation-cohort-2025`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={companiesListBanner ? companiesListBanner : gtiLogo}
        />
        <meta name="twitter:card" content={title} />
      </Helmet>
      <div className="relative w-full">
        <img
          src={companiesListBanner}
          alt="Selected Companies"
          className="w-full object-cover"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="pl-8">
            <h1 className="text-white text-xl sm:text-5xl font-bold sm:w-7/12">
              Selected companies in Dignified Jobs Accelerator Circular
              Innovation Cohort 2025
            </h1>
          </div>
        </div>
      </div>
      <div className="flex items-center bg-white shadow-md rounded-full p-1 w-full max-w-lg mx-auto">
        <input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          type="text"
          placeholder="Search"
          className="flex-grow outline-none text-gray-600 placeholder-gray-400 bg-transparent px-4 border-none rounded-full"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center gap-2 sm:gap-10 w-fit sm:w-full m-auto">
        <CompanyDropdownFilter
          label="Sectors"
          options={cohortCompanySectors}
          selectedValue={sector}
          onSelect={setSector}
        />
        <CompanyDropdownFilter
          label="Area of Work Under Sector"
          options={cohortCompanyAreaOfWorkUnderSector}
          selectedValue={selectedSubSector}
          onSelect={setSelectedSubSector}
        />
      </div>
      <div
        className={`grid m-2 sm:grid-rows-${Math.ceil(
          filteredCompanies?.length / 3
        )} ${filteredCompanies?.length ? "sm:grid-cols-3" : ""} gap-10 w-full`}
      >
        {filteredCompanies?.length ? (
          filteredCompanies.map((company: any, index: number) => (
            <CompanyCard
              key={index}
              productId={company?.productDetails?._id?.$oid}
              logo={company?.companyDetails?.logo}
              title={company?.companyDetails?.name}
              description={company?.companyDetails?.description}
            />
          ))
        ) : (
          <p className="text-gray-500 text-center w-full">No Companies Found</p>
        )}
      </div>
      {/* <div className="flex items-center justify-center gap-10">
        <button
          onClick={goToPreviousPage}
          className={`px-4 py-2 rounded-md text-white ${
            currentPage === 1
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          className={`px-4 py-2 rounded-md text-white ${
            currentPage === totalPages
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default Companies;
